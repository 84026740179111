<template lang='pug'>
font-awesome-layers.fa-fw
  font-awesome-icon( :icon="notificationCount > 0 ? ['fas', 'bell'] : ['far', 'bell']" )
  font-awesome-layers-text( counter=true :value='notificationCount' v-if='notificationCount > 0' )
</template>

<script>
import Axios from 'axios'

export default {
  name: 'notification-bell',

  data () {
    return {
      notificationCount: 0
    }
  },

  mounted () {
    const that = this
    Axios
      .get('/notifications.json')
      .then(response => {
        that.notificationCount = response.data.filter(x => (!x.read)).length
      })
  }
}
</script>

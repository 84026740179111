<template lang="pug">
div

  // list
  .list-group(v-if='sources.length > 0')
    .list-group-item.list-group-item-action(v-for='source in sources')
      .d-flex.align-items-center
        .flex-grow-1
          a(:href='source.edit_url')
            font-awesome-icon(:icon='sourceIcon(source)')
            span.ml-2 {{ source.display }}
        div
          a.btn.btn-danger(:href='source.delete_url' 'data-method'='delete' 'data-confirm'='Are you sure?')
            font-awesome-icon(icon='trash')

  // add
  a.btn.btn-primary.mt-1(v-if='sources.length < 2' :href='newSourcePath')
    font-awesome-icon(icon='plus')
    span.ml-2 Add source
</template>

<script>
export default {
  name: 'source-list',

  props: {
    newSourcePath: {
      type: String,
      default: '/sources/new'
    },
    sources: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    sourceIcon (source) {
      if (source.kind === 'spotify') { return ['fab', 'spotify'] }
      if (source.kind === 'youtube') { return ['fab', 'youtube'] }
      else { return ['far', 'question-circle'] }
    }
  }
}
</script>

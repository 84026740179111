<template lang="pug">
.overflow-md-hidden
  .row
    .col-12.text-center
      h1.mb-3.text-uppercase.font-weight-bold {{ pageTitle }}

    .col-lg-4.col-12#sidebar

      .card
        .card-header Edit source
        .card-body
          .alert.alert-danger(v-if='formErrors.message' role='alert') {{ formErrors.message }}
          .alert.alert-success(v-if='formNotice' role='alert') {{ formNotice }}

          .form-group
            label(for='source_foreign_id') Foreign ID
            input.form-control#source_foreign_id(
              type='text'
              name='source[foreign_id]'
              v-model='source.foreign_id'
              :placeholder='defaultForeignID'
            )

          .form-group
            label(for='source_kind') Kind
            select.custom-select#source_kind(name='source[kind]'
                                             v-model='source.kind'
                                             :class="{ 'is-invalid': formErrors.kind }")
              option(value='spotify') Spotify
              option(value='youtube') YouTube
            .invalid-feedback {{ printErrors('Kind', formErrors.kind) }}

          .form-group(v-if='source.kind == "youtube"')
            label(for='source_youtube_kind') YouTube kind
            select.custom-select#source_youtube_kind(name='source[youtube_kind]'
                                             v-model='source.youtube_kind'
                                             :class="{ 'is-invalid': formErrors.youtube_kind }")
              option(value='channel') Channel
              option(value='user') User

          .form-group
            .custom-control.custom-switch
              input.custom-control-input#source_scheduled(
                type='checkbox'
                v-model='source.scheduled'
              )
              label.custom-control-label(for='source_scheduled') Scheduled
            small.form-text.text-muted Whether or not to automatically generate episodes every night.

          .form-group
            label(for='source_filter_pattern') Filter pattern
            input.form-control#source_filter_pattern(
              type='text'
              v-model='source.filter_pattern'
              name='source[filter_pattern]'
            )
            small.form-text.text-muted Episodes must match this pattern. Leave blank to accept all.

          .form-group
            label(for='source_title_pattern') Title pattern
            input.form-control#source_title_pattern(
              type='text'
              v-model='source.title_pattern'
              name='source[title_pattern]'
            )
            small.form-text.text-muted Used to extract part of the title, for example to remove prefix. Leave blank to use whole title.

          .form-group
            .custom-control.custom-switch
              input.custom-control-input#source_parse_title_for_guests(
                type='checkbox'
                v-model='source.parse_title_for_guests'
              )
              label.custom-control-label(for='source_parse_title_for_guests') Parse title for guests
            small.form-text.text-muted Whether or not to extract guests from title.

          div(v-show='source.parse_title_for_guests')
            .form-group
              label(for='source_guests_pattern') Guests pattern
              input.form-control#source_guest_pattern(
                type='text'
                v-model='source.guests_pattern'
                name='source[guests_pattern]'
              )
              small.form-text.text-muted Used to extract guests from title.

            .form-group
              label(for='source_split_pattern') Split pattern
              input.form-control#source_split_pattern(
                type='text'
                v-model='source.split_pattern'
                name='source[split_pattern]'
              )
              small.form-text.text-muted Used to split guest section into list of names.

          .actions.d-flex
            .flex-grow-1
              a.btn.btn-primary(@click='saveForm')
                font-awesome-icon(icon='check')
                span.ml-2 Save

              a.btn.btn-link(:href='url.cancel') Cancel
            div(v-if='persisted')
              a.btn.btn-danger(:href='url.delete' data-method='delete' data-confirm='Are you sure?')
                font-awesome-icon(icon='trash')
                span.ml-2 Remove

    .col-lg-8.col-12

      .card
        .card-header Fetch episodes

        //- messages
        .card-body(v-if='fetchErrors.message')
          .alert.alert-danger(role='alert') {{ fetchErrors.message }}
        .card-body(v-if='fetchNotice')
          .alert.alert-success(v-if='fetchNotice' role='alert') {{ fetchNotice }}

        //- source does not exist yet
        .card-body(v-if='!persisted')
          .my-5.text-center.text-muted.font-italic Save source before generating episodes.

        //- fetching
        .card-body(v-else-if='fetching')
          .my-5.text-center.text-muted
            h3
              font-awesome-icon(icon='spinner' pulse)
            h5.pb-4
              .mt-4 Fetching episodes...

        //- generating
        .card-body(v-else-if='generating')
          .my-5.text-center.text-muted
            h3
              font-awesome-icon(icon='spinner' pulse)
            h5.pb-4
              .mt-4 Generating episodes...

        //- empty
        .card-body.py-4(v-else-if='!anyEpisodes')
          .my-5.text-center
            a.btn.btn-primary.btn-lg(@click='fetch()')
              font-awesome-icon(icon='cloud-download-alt')
              span.ml-3 Fetch episodes

        //- list
        .list-group.list-group-flush(v-else='')
          .list-group-item.mb-3.d-flex
            a.btn.btn-primary(@click='fetch()')
              font-awesome-icon(icon='cloud-download-alt')
              span.ml-2 Fetch episodes
            a.btn.btn-success.ml-3(@click='generate()')
              font-awesome-icon(icon='plus-circle')
              span.ml-2 Generate episodes
          .list-group-item(v-for='(episode, index) in fetchResult.episodes' :key='index')
            .d-flex
              .mr-3
                img.size-75(:src='episode.image')
              .flex-grow-1
                input.form-control.form-control-lg(type='text' v-model='episode.title')
                textarea.form-control.form-control-sm.mt-2(type='text' v-model='episode.description' rows='5')
                tag-input.mt-2(v-model='episode.guests')
              .align-self-top
                a.btn.btn-link.btn-sm(href='#' @click.prevent='removeEpisode(index)')
                  font-awesome-icon(icon='times')
                .px-2(v-if='episode.exists' title='Episode exists and will merge.')
                  font-awesome-icon(icon='compress-alt')

</template>

<script>
import Axios from 'axios'

export default {
  name: 'source-form',

  props: {
    showJson: {
      type: Object,
      default: () => {}
    },
    sourceJson: {
      type: Object,
      default: () => {}
    },
    createUrl: {
      type: String,
      default: '/sources.json'
    },
    updateUrl: {
      type: String,
      default: null
    },
    fetchUrl: {
      type: String,
      default: null
    },
    generateUrl: {
      type: String,
      default: null
    },
    cancelUrl: {
      type: String,
      default: null
    },
    deleteUrl: {
      type: String,
      default: null
    }
  },

  computed: {
    pageTitle () {
      return this.persisted ? 'Update source' : 'Create source'
    },
    defaultForeignID () {
      if (this.showJson) {
        return this.showJson[this.source.kind] || ''
      }
      return ''
    },
    anyEpisodes() {
      return this.fetchResult?.episodes?.length > 0
    }
  },

  data () {
    return {
      formErrors: {},
      fetchErrors: {},
      formNotice: '',
      fetchNotice: '',
      source: {},
      persisted: false,
      fetching: false,
      generating: false,
      fetchResult: {},
      url: {
        form: null,
        fetch: null,
        generate: null,
        cancel: null,
        delete: null
      }
    }
  },

  mounted () {
    // copy props to data since Vue does not allow us to pass initial values to mutable variables from HTML page.
    this.source = this.sourceJson
    this.persisted = this.updateUrl != null
    this.url.form = this.updateUrl || this.createUrl
    if (this.fetchUrl) { this.url.fetch = this.fetchUrl }
    if (this.cancelUrl) { this.url.cancel = this.cancelUrl }
    if (this.generateUrl) { this.url.generate = this.generateUrl }
    if (this.deleteUrl) { this.url.delete = this.deleteUrl }

    if (!this.source.kind) { this.source.kind = 'spotify' }
  },

  methods: {
    saveForm () {
      let req
      if (this.persisted) {
        req = Axios.put(this.url.form, { source: this.source })
      } else {
        req = Axios.post(this.url.form, { source: this.source })
      }
      req.then(this.saveFormSuccess).catch(this.saveFormError)
    },

    saveFormSuccess (response) {
      this.formError = null
      this.formNotice = 'Source saved successfully.'
      this.persisted = true
      this.url.form = response.data.url
      this.url.fetch = response.data.fetch_url
      this.url.generate = response.data.generate_url
      this.url.delete = response.data.delete_url
    },

    saveFormError (error) {
      if (error?.response?.data) {
        this.formErrors = error.response.data
      } else {
        this.formErrors = { message: error }
      }
    },

    fetch () {
      this.fetching = true
      Axios.get(this.url.fetch).then(this.fetchSuccess).catch(this.fetchError)
    },

    fetchSuccess (response) {
      this.fetching = false
      this.fetchResult = response.data
    },

    fetchError (error) {
      this.fetching = false
      if (error?.response?.data) {
        this.fetchErrors = error.response.data
      } else {
        this.fetchErrors = { message: error }
      }
    },

    removeEpisode (index) {
      this.fetchResult.episodes.splice(index, 1)
    },

    generate () {
      const r = confirm('Are you sure?')
      if (r === true) {
        this.generating = true
        Axios.post(this.url.generate, this.fetchResult).then(this.generateSuccess).catch(this.generateError)
      }
    },

    generateSuccess (response) {
      this.generating = false
      this.fetchErrors = {}
      this.fetchNotice = 'Episodes were successfully generated.'
      this.fetchResult = {}
    },

    generateError (error) {
      this.generating = false
      if (error?.response?.data) {
        this.fetchErrors = error.response.data
      } else {
        this.fetchErrors = { message: error }
      }
    },

    printErrors (field, errors) {
      if (errors) {
        return errors.map(e => { return `${field} ${e}.` }).join('<br/>')
      }
      return ''
    }
  }
}
</script>

<template lang='pug'>
div
  h1.mb-3.text-center {{ headermerge }}

  //- error
  ul.list-group.list-group-flush(v-if='error')
    li.list-group-item.list-group-item-danger {{ error }}

  //- loading
  ul.list-group(v-else-if='loading')
    li.list-group-item(v-for='index in 1' :key='index')
      slot(name='loading')

  div(v-else='')

    .text-center
      img.size-75.border(:src='resource.image')
      h5.mt-3 {{ resource.display_name }}

  h2.text-center.mt-5.mb-2 {{ headerwith }}

  .w-50.m-auto
    autocomplete#original(
      name='original_autocomplete'
      :placeholder='placeholder'
      :size='size'
      :getResults='search'
      :resultType='resulttype'
      :inputClass='inputclass'
      :inputWrapperClass='inputwrapperclass'
      :autofocus='autofocus'
      @select='select'
    )

  input(type='hidden' :value='formValue' name='original_id')
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    url: {
      type: String
    },
    headermerge: {
      type: String,
      default: 'Merge'
    },
    headerwith: {
      type: String,
      default: 'with'
    },
    mergebutton: {
      type: String,
      default: 'Merge'
    },
    resulttype: {
      type: String,
      default: 'dropdown'
    },
    inputclass: {
      type: String,
      default: 'form-control form-control-sm form-search mr-sm-2'
    },
    inputwrapperclass: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '40'
    },
    placeholder: {
      type: String,
      default: 'Original'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    searchurl: {
      type: String,
      default: '/search.json'
    }
  },
  data () {
    return {
      debug: [],
      original: null,
      resource: null,
      error: '',
      notice: '',
      loading: true
    }
  },
  computed: {
    formValue () {
      if (this.original) {
        return this.original.slug
      } else {
        return null
      }
    }
  },
  methods: {
    search (query) {
      const that = this
      return new Promise(function (resolve, reject) {
        Axios
          .get(that.searchurl, { params: { q: query } })
          .then(response => { resolve(response.data) })
          .catch(error => { reject(error) })
      })
    },
    select (result) {
      this.original = result.item
    },
    clear () {
      this.original = null
    }
  },
  mounted () {
    const that = this

    Axios
      .get(this.url)
      .then(response => {
        that.resource = response.data
        that.loading = false
      })
      .catch(error => {
        that.error = `Error fetching resource: ${error}`
        that.loading = false
      })
  }
}
</script>

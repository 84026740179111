<template lang='pug'>
ul.list-group.list-group-flush

  //- empty
  li.list-group-item(v-if='episodes.length == 0')
    .my-5.text-muted.text-center.font-italic {{ emptyMessage }}

  //- list
  li.list-group-item.d-flex(
    v-else=''
    v-for='(episode, index) in episodes' :key='episode.id'
    :data-episode-id='episode.id'
  )

    //- image
    .list-group-item-image
      a(:href='episode.view_url')
        img.rounded.shadow-sm.border(:src='episode.image')

    //- body
    .list-group-item-body

      //- title
      h5.list-group-item-title
        a(:href='episode.view_url') {{ episode.title }}

      //- description
      .list-group-item-description(v-if='episode.description' v-html='episode.description')
      .list-group-item-description(v-else='')
        .font-italic Description is missing.

      //- meta data
      .row.list-group-item-meta

        .col-12.col-md-4.col-lg-3.list-group-item-meta-group
          .list-group-item-meta-label
            i.fa.fa-fw.fa-tv
            span Show
          .list-group-item-meta-value
            a(:href='episode.show.view_url') {{ episode.show.title }}

        .col-12.col-md-4.col-lg-3.list-group-item-meta-group
          .list-group-item-meta-label
            i.far.fa-fw.fa-calendar
            span Aired
          .list-group-item-meta-value {{ episode.aired_at_human }}

        .col-12.col-md-4.col-lg-6.list-group-item-meta-group
          .list-group-item-meta-label
            i.fa.fa-fw.fa-user-tie
            span Guests
          .list-group-item-meta-value(v-html='guestLinks(episode.people)')

    //- buttons
    .list-group-item-buttons
      .dropdown
        a(type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
          i.fa.fa-fw.fa-ellipsis-v
        .dropdown-menu.dropdown-menu-right
          a.dropdown-item(v-if='episode.report_url' :href='episode.report_url')
            i.fa.fa-fw.fa-flag
            span Report
          a.dropdown-item(v-if='episode.edit_url' :href='episode.edit_url')
            i.fa.fa-fw.fa-pen
            span Edit
          a.dropdown-item(v-if='episode.merge_url' :href='episode.merge_url')
            i.fa.fa-fw.fa-compress-alt
            span Merge
          a.dropdown-item.text-danger(v-if='episode.destroy_url' :href='episode.destroy_url' data-method='delete'
                                      data-confirm='Are you sure?')
            i.fa.fa-fw.fa-trash-alt
            span Remove
</template>

<script>
export default {
  props: {
    collection: {
      type: Array,
      default: () => []
    },
    emptyMessage: {
      type: String,
      default: 'No episodes found.'
    }
  },
  data () {
    return {
      episodes: []
    }
  },
  methods: {
    guestLinks (people) {
      return people.map(p => this.guestLink(p)).join(', ')
    },
    guestLink (person) {
      return `<a href='${person.view_url}'>${person.name}</a>`
    }
  },
  created () {
    this.episodes = this.collection
  }

}
</script>

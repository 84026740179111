<template lang='pug'>
.card
  .card-header {{ header }}

  //- error
  ul.list-group.list-group-flush(v-if='error')
    li.list-group-item.list-group-item-danger {{ error }}

  //- loading
  ul.list-group.list-group-flush(v-else-if='loading')
    li.list-group-item(v-for='index in 5' :key='index')
      slot(name='loading')

  //- list
  slot(v-else='' v-bind:collection='collection' name='content')

  //- pagination controls
  .card-footer.p-0(v-if='pages > 1')
    nav(aria-label='Pagination')
      ul.pagination.mb-0.justify-content-center
        li.page-item(:class='{ "disabled": isFirst() }')
          a.page-link(:href='urlFor(page-1)' :aria-label='previousLabel' @click.prevent='previousPage()')
            span(aria-hidden='true') &laquo;
        li.page-item(
          v-for='(page,index) in series'
          :key='index'
          :class='pageClass(page)'
        )
          span(v-if="page == 'gap'") ···
          span(v-else='')
            a.page-link(:href='urlFor(page)' @click.prevent='toPage(page)') {{ page }}
        li.page-item(:class='{ "disabled": isLast() }')
          a.page-link(:href='urlFor(page+1)' :aria-label='nextLabel' @click.prevent='nextPage()')
            span(aria-hidden='true') &raquo;

</template>

<script>
import Axios from 'axios'
import GoogleAnalytics from '../packs/googleAnalytics'

export default {
  props: {
    url: {
      type: String,
      default: '/people.json'
    },
    header: {
      type: String,
      default: 'List'
    },
    previousLabel: {
      type: String,
      default: 'Previous'
    },
    nextLabel: {
      type: String,
      default: 'Next'
    },
    prefix: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      error: '',
      loading: true,
      collection: [],
      series: ['1'],
      page: 1,
      pages: 1
    }
  },

  methods: {
    isFirst () { return this.page === 1 },
    isLast () { return this.page === this.pages },
    pageClass (page) {
      const classes = []
      if (typeof page === 'string') { classes.push('active') }
      if (page === 'gap') { classes.push('page-gap') }
      return classes.join(' ')
    },
    urlFor (page, prefix = this.prefix) {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set(`${prefix}page`, page)
      return `${window.location.pathname}?${queryParams}`
    },
    previousPage () {
      this.toPage(this.page - 1)
    },
    nextPage () {
      this.toPage(this.page + 1)
    },
    toPage (page) {
      if (page < 1 || page > this.pages) { return }
      this.page = page
      const url = this.urlFor(page)
      window.history.pushState({ path: url }, '', url)
      this.fetch()
    },
    fetch () {
      this.loading = true
      Axios.get(`${this.url}?page=${this.page}`).then(this.fetchSuccess).catch(this.fetchError)
    },
    fetchSuccess (response) {
      this.collection = response.data.data
      this.series = response.data.pagination.series
      this.page = response.data.pagination.page
      this.pages = response.data.pagination.pages
      this.loading = false
    },
    fetchError (error) {
      this.error = error
      this.loading = false
    },
    initLoad () {
      const queryParams = new URLSearchParams(window.location.search)
      this.page = queryParams.get(`${this.prefix}page`) || 1
      this.fetch()
    }
  },

  mounted () {
    const tokenElement = document.getElementsByName('csrf-token')
    if (tokenElement.length > 0) {
      const token = tokenElement[0].getAttribute('content')
      Axios.defaults.headers.common['X-CSRF-Token'] = token
      Axios.defaults.headers.common.Accept = 'application/json'
    }
    window.onpopstate = this.initLoad
    this.initLoad()
  }

}
</script>

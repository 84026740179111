<template lang='pug'>
.tag-input.d-flex.align-items-center.row-gap-2.flex-wrap
  a.badge.badge-primary.px-3.py-2.mr-2.d-flex.align-items-center(
    v-for='(tag, index) in tags'
    href='#'
    @click.prevent='removeTag(index)'
  )
    span.mr-2.font-size-10.tag-label {{ tag }}
    font-awesome-icon(icon='times')
  input.ml-1.flex-grow-1(
    type='text'
    v-model='newTag'
    :id='id'
    @input='onChange'
    @keydown.enter.prevent='onEnter'
  )
  input(type='hidden', :name='name + "[]"' :value='tag' v-for='tag in tags')
</template>

<script>
export default {
  name: 'tag-input',

  props: {
    value: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: 'resource[tags]'
    },
    id: {
      type: String,
      default: 'resource_tags'
    }
  },

  data () {
    return {
      tags: [],
      newTag: ''
    }
  },

  watch: {
    value: function (newValue, oldValue) {
      this.$emit('input', newValue)
      this.refresh()
    }
  },

  methods: {
    removeTag (index) {
      this.tags.splice(index, 1)
    },
    addTag (tag) {
      if (tag.trim().length > 2) {
        this.tags.push(tag.trim())
      }
    },
    onEnter () {
      this.addTag(this.newTag)
      this.newTag = ''
    },
    refresh () {
      this.tags = this.value
    },
    onChange () {
      if (this.newTag.indexOf(',') !== -1) {
        for (const tag of this.newTag.split(',')) {
          this.addTag(tag)
        }
        this.newTag = ''
      }
    }
  },

  created () {
    this.refresh()
  }
}
</script>

<style>
.tag-input a:last-of-type {
  margin-right: 0.3rem !important;
}

.tag-input input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
}

.tag-input input:focus {
  border: none;
  box-shadow: none;
}
</style>

<template lang='pug'>
div
  ul( :class='listClass' )

    //- empty
    li.list-group-item(v-if='hosts.length == 0 && showEmpty')
      .text-muted.font-italic {{ emptyLabel }}

    //- list
    li.list-group-item(
      v-for='(person, index) in hosts'
      :person='person'
    )
      .d-flex.align-items-center
        img.size-25.border.mr-3.rounded(:src='hostImage(person)')
        .flex-grow-1 {{ person.name }}
        div(v-if='showRemove')
          a.btn.btn-sm.btn-outline-danger(@click.prevent='removeHost(index)')
            font-awesome-icon(icon='trash')
            span.ml-1 {{ removeLabel }}

  //- add button
  .mt-2(v-if='showAdd')
    .d-flex.align-items-center
      .flex-grow-1
        autocomplete(
          :placeholder='addPlaceholderLabel'
          :getResults='searchHosts'
          @enter='onEnter'
          v-model='newHost'
          ref='hostAutocomplete'
        )
      .ml-2
        button(class='btn btn-primary' @click.prevent='addHost')
          font-awesome-icon(icon='plus')
          span.ml-2 {{ addLabel }}

  input(type='hidden', :name='name + "[]"' :value='person.id || person.name' v-for='person in hosts')

</template>

<script>
import Axios from 'axios'

export default {
  name: 'host-list',

  props: {
    value: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: 'resource[hosts]'
    },
    listClass: {
      type: String,
      default: 'list-group'
    },
    defaultImage: {
      type: String,
      default: '/assets/placeholder-person.png'
    },
    showRemove: {
      type: Boolean,
      default: false
    },
    showAdd: {
      type: Boolean,
      default: false
    },
    showEmpty: {
      type: Boolean,
      default: false
    },
    emptyLabel: {
      type: String,
      default: 'This show has no hosts.'
    },
    removeLabel: {
      type: String,
      default: 'Remove'
    },
    addLabel: {
      type: String,
      default: 'Add host'
    },
    addPlaceholderLabel: {
      type: String,
      default: 'Name of host'
    }
  },

  data () {
    return {
      hosts: [],
      newHost: {}
    }
  },

  watch: {
    value: function (newValue, oldValue) {
      this.$emit('input', newValue)
    }
  },

  methods: {
    searchHosts (query) {
      const existingMembers = this.hosts.map(x => x.id)
      return new Promise(function (resolve, reject) {
        Axios
          .get('/people.json', { params: { q: query } })
          .then(response => {
            resolve(
              response.data.filter(
                x => (existingMembers.indexOf(x.id) === -1)
              ).slice(0, 5)
            )
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    hostImage (host) {
      if (host.image) {
        return host.image
      } else {
        return this.defaultImage
      }
    },
    removeHost (index) {
      this.hosts.splice(index, 1)
    },
    addHost () {
      if (this.newHost !== {}) {
        this.hosts.push(this.newHost)
        this.newHost = {}
        this.$refs.hostAutocomplete.clear()
      }
    },
    onEnter (value) {
      this.newHost = value
      this.addHost()
    }
  },
  created () {
    this.hosts = this.value
  }

}
</script>

<template lang='pug'>
ul.list-inline(@mouseout='clearHover()')
  li.list-inline-item(v-for='n in 5' @mouseover='setHover(n)')
    a(href='#' @click.prevent='rate(n)')
      font-awesome-icon(:icon='iconFor(n)')

</template>

<script>
import Axios from 'axios'
import EventBus from '../packs/eventBus'
import GoogleAnalytics from '../packs/googleAnalytics'

export default {
  props: {
    rateUrl: {
      type: String,
      default: '/rate.json'
    },
    ratingUrl: {
      type: String,
      default: '/rating.json'
    }
  },
  data() {
    return {
      rating: 0,
      myRating: -1,
      hoverRating: -1,
      loading: false,
      clearHoverTimer: null,
      clearHoverTimout: 500
    }
  },
  methods: {

    iconFor (value) {
      if ((this.hoverRating > 0 && this.hoverRating >= value) || (this.hoverRating == -1 && this.myRating >= value)) {
        return ['fas', 'star']
      } else {
        return ['far', 'star']
      }
    },
    setHover (value) {
      clearTimeout(this.clearHoverTimer)
      this.hoverRating = value || -1
    },
    clearHover () {
      clearTimeout(this.clearHoverTimer)
      this.clearHoverTimer = setTimeout(this.setHover, this.clearHoverTimout)
    },
    rate (value) {
      this.loading = true
      let params = { rating: { value: value } }
      GoogleAnalytics.event('rate', { value: value })
      Axios.post(this.rateUrl, params).then(this.rateSuccess).catch(this.rateError)
    },
    rateSuccess (response) {
      this.loading = false
      this.rating = response.data.rating
      this.myRating = response.data.my_rating
      if (response.data.message) {
        EventBus.$emit('alert', { type: 'success', message: response.data.message })
      }
    },
    rateError (error) {
      this.loading = false
      this.sendError(error, 'rate')
    },
    ratingSuccess (response) {
      this.loading = false
      this.rating = response.data.rating
      this.myRating = response.data.my_rating
    },
    ratingError (error) {
      this.loading = false
      this.sendError(error, 'rate')
    },
    sendError (error, action) {
      if (error?.response?.data?.error) {
        GoogleAnalytics.event('error', { action: action, error: error.response.data })
        EventBus.$emit('alert', { type: 'danger', message: error.response.data.message })
      } else if (error?.response?.data && Array.isArray(error.response.data)) {
        GoogleAnalytics.event('error', { action: action, error: error.response.data })
        for (const e of error.response.data) {
          EventBus.$emit('alert', { type: 'danger', message: e })
        }
      } else {
        GoogleAnalytics.event('error', { action: action, error: error })
        EventBus.$emit('alert', { type: 'danger', message: error })
      }
    }
  },
  mounted () {
    const tokenElement = document.getElementsByName('csrf-token')
    if (tokenElement.length > 0) {
      const token = tokenElement[0].getAttribute('content')
      Axios.defaults.headers.common['X-CSRF-Token'] = token
      Axios.defaults.headers.common.Accept = 'application/json'
    }
    this.loading = true
    Axios.get(this.ratingUrl).then(this.ratingSuccess).catch(this.ratingError)
  }

}
</script>

<template lang='pug'>
ul.list-group.list-group-flush

  //- empty
  li.list-group-item(v-if='people.length == 0')
    .my-5.text-muted.text-center.font-italic {{ emptyMessage }}

  //- list
  li.list-group-item.d-flex(v-else='' v-for='(person, index) in people' :key='person.id')

    //- image
    .list-group-item-image
      a(:href='urlForPerson(person)')
        img.rounded.shadow-sm.border(:src='person.image')

    //- body
    .list-group-item-body

      //- title
      h5.list-group-item-title
        a(:href='urlForPerson(person)') {{ person.name }}

      //- description
      .list-group-item-description(v-if='person.description' v-html='person.description')
      .list-group-item-description(v-else='')
        .font-italic Description is missing.

      //- meta data
      .row.list-group-item-meta

        .col-12.col-md-4.col-lg-3.list-group-item-meta-group
          .list-group-item-meta-label
            i.fa.fa-fw.fa-user-tie
            span Shows
          .list-group-item-meta-value {{ person.show_count }}

        .col-12.col-md-4.col-lg-3.list-group-item-meta-group
          .list-group-item-meta-label
            i.fa.fa-fw.fa-tv
            span Episodes
          .list-group-item-meta-value {{ person.episode_count }}

        .col-12.col-md-4.col-lg-6.list-group-item-meta-group
          .list-group-item-meta-label
            i.fa.fa-fw.fa-thumbs-up
            span Votes
          .list-group-item-meta-value {{ person.vote_count }}

    //- buttons
    .list-group-item-buttons.d-flex.align-items-center
      a.btn.btn-link(
        href='#'
        @click.prevent='favorite(person, !person.favorited)'
        :class='{ disabled: favoriting }'
        :aria-label='favoriteLabel(person)'
        :title='favoriteLabel(person)'
      )
        font-awesome-icon( :icon="[person.favorited ? 'fas' : 'far', 'heart']" )
      a.btn.btn-link(
        href='#'
        @click.prevent='toggleVote(person)'
        :class='{ disabled: voting }'
        :aria-label='voteLabel(person)'
        :title='voteLabel(person)'
      )
        font-awesome-icon( :icon="[person.voted ? 'fas' : 'far', 'thumbs-up']" )
      .dropdown(v-if='!hideControls')
        a.btn.btn-link.p-1(type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
          i.fa.fa-fw.fa-ellipsis-v
        .dropdown-menu.dropdown-menu-right
          a.dropdown-item(v-if='person.report_url' :href='person.report_url')
            i.fa.fa-fw.fa-flag
            span Report
          a.dropdown-item(v-if='person.edit_url' :href='person.edit_url')
            i.fa.fa-fw.fa-pen
            span Edit
          a.dropdown-item.text-danger(v-if='person.destroy_url' :href='person.destroy_url' data-method='delete'
                                      data-confirm='Are you sure?')
            i.fa.fa-fw.fa-trash-alt
            span Remove

  //- new
  li.list-group-item(v-if='showNewGuest')
    .d-flex.align-items-center
      .flex-grow-1
        autocomplete(
          :placeholder='newGuestPlaceholder'
          :getResults='search'
          :disabled='voting'
          v-model='newGuest'
          ref='guestAutocomplete'
        )
      div
        a.btn.btn-link(
          href='#'
          @click.prevent='voteForNew(1)'
          :class='{ disabled: voting }'
          :aria-label='voteNewLabel()'
          :title='voteNewLabel()'
        )
          i.fas.fa-thumbs-up

</template>

<script>
import Axios from 'axios'
import EventBus from '../../packs/eventBus'
import GoogleAnalytics from '../../packs/googleAnalytics'

export default {
  props: {
    collection: {
      type: Array,
      default: () => []
    },
    showId: {
      type: String,
      default: '0'
    },
    showNewGuest: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    newGuestPlaceholder: {
      type: String,
      default: 'Guest name'
    },
    emptyMessage: {
      type: String,
      default: 'No people found.'
    }
  },
  data() {
    return {
      people: [],
      voting: false,
      favoriting: false,
      newGuest: { name: '' }
    }
  },
  methods: {

    favoriteLabel (person) { return `Mark ${person.name} as favorite` },
    voteLabel (person) { return `Vote for ${person.name}` },
    voteNewLabel () { return 'Vote for new person' },

    urlForPerson (person) {
      return person.show_url || person.view_url
    },

    search (query) {
      const people = this.people.map(x => x.id)
      return new Promise(function (resolve, reject) {
        Axios
          .get('/people.json', { params: { q: query } })
          .then(response => {
            resolve(
              response.data.filter(
                x => (people.indexOf(x.id) === -1)
              ).slice(0, 5)
            )
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    voteForNew (value) {
      this.voting = true
      this.error = ''
      this.notice = ''
      let that = this
      let params = { person_name: this.newGuest.name, vote: { value: value, show_id: that.showId } }
      return Axios
        .post(`/votes.json`, params)
        .then(response => {
          that.newGuest = { name: '' }
          that.people.push(response.data.vote.person)
          that.voteSuccess(response, params)
        })
        .catch(this.voteError)
    },
    toggleVote (person) {
      let value = 1
      if (person.voted) { value = -1 }
      return this.vote(person, value)
    },
    vote (person, value) {
      this.voting = true
      this.error = ''
      this.notice = ''
      var that = this
      let params = { vote: { person_id: person.id, show_id: that.showId, value: value } }
      return Axios
        .post(`/votes.json`, params)
        .then(response => {
          person.voted = response.data.vote.person.voted
          that.voteSuccess(response, params, person)
        })
        .catch(this.voteError)
    },
    voteSuccess (response, params) {
      this.voting = false
      GoogleAnalytics.event('vote', params)
      if (response.data.limit) {
        EventBus.$emit('alert', { type: 'success', message: response.data.limit.message })
      }
    },
    voteError (error) {
      this.voting = false
      if (error?.response?.data?.error) {
        GoogleAnalytics.event('error', { action: 'vote', error: error.response.data })
        EventBus.$emit('alert', {
          type: 'danger',
          message: error.response.data.message
        })
      } else if (error?.response?.data && Array.isArray(error.response.data)) {
        GoogleAnalytics.event('error', { action: 'vote', error: error.response.data })
        for (const e of error.response.data) {
          EventBus.$emit('alert', { type: 'danger', message: e })
        }
      } else {
        GoogleAnalytics.event('error', { action: 'vote', error: error })
        EventBus.$emit('alert', { type: 'danger', message: error })
      }
    },
    favorite (person, value) {
      this.favoriting = true
      this.error = ''
      this.notice = ''
      const that = this
      return Axios
        .post(`/people/${person.slug}/favorite.json`, { value: value })
        .then(response => {
          GoogleAnalytics.event('favorite', { person_id: person.id, person_name: person.name })
          person.favorited = response.data.favorited
          that.favoriting = false
        })
        .catch(error => {
          GoogleAnalytics.event('error', { action: 'favorite', error: error })
          that.favoriting = false
          EventBus.$emit('alert', { type: 'danger', message: error })
        })
    }
  },
  created () {
    const tokenElement = document.getElementsByName('csrf-token')
    if (tokenElement.length > 0) {
      const token = tokenElement[0].getAttribute('content')
      Axios.defaults.headers.common['X-CSRF-Token'] = token
      Axios.defaults.headers.common.Accept = 'application/json'
    }
    this.people = this.collection
  }

}
</script>

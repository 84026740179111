<template lang='pug'>
.list-group.list-group-flush

  // no notifications
  .list-group-item.list-group-item-action.p-3( v-if='notifications == 0' )
    .text-muted.font-italic You have no notifications.

  // notification list
  .list-group-item.list-group-item-action.p-3(
    v-for='notification in notifications' :key='notification.id'
    :class="[ notification.read ? 'notification-read' : 'notification-unread' ]"
  )
    .d-flex.w-100
      img.rounded-circle.border( :src='notification.image' )
      .flex-grow-1.ml-2.text-truncate
        h5.mb-1.text-truncate {{ notification.title }}
        p.mb-1.text-wrap( v-html='notification.content' )
        small {{ notification.created_ago }} ago
      .ml-2
        a(href='#' @click.prevent='removeNotification(notification)')
          font-awesome-icon(icon='times')
</template>

<script>
export default {
  name: 'notification-list',
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    removeNotification (notification) {
      this.$emit('removeNotification', notification)
    }
  }
}
</script>

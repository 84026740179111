<template lang='pug'>
div
  //- error
  ul.list-group.list-group-flush(v-if='error')
    li.list-group-item.list-group-item-danger {{ error }}

  //- loading
  ul.list-group.list-group-flush(v-else-if='loading')
    li.list-group-item(v-for='index in 5' :key='index')
      slot(name='loading')

  //- list
  lists-shows(
    v-else=''
    :collection='shows'
    :emptyMessage='emptyMessage'
    :personId='personId',
    :showNewShow='showNewShow',
    :hideControls='hideControls',
    :newShowPlaceholder='newShowPlaceholder'
  )
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    url: {
      type: String,
      default: '/shows.json'
    },
    personId: {
      type: String,
      default: '0'
    },
    showNewShow: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    emptyMessage: {
      type: String,
      default: 'No shows found.'
    },
    newShowPlaceholder: {
      type: String,
      default: 'Show name'
    }
  },
  data() {
    return {
      shows: [],
      loading: true,
      error: ''
    }
  },
  created () {
    var that = this
    Axios
      .get(this.url)
      .then(response => {
        that.shows = response.data
        that.loading = false
      })
      .catch(error => {
        that.error = `Error fetching shows: ${error}`
        that.loading = false
      })
  }

}
</script>

<template lang='pug'>
ul.list-group.list-group-flush

  //- messages
  li.list-group-item.list-group-item-danger(v-if='error') {{ error }}
  li.list-group-item.list-group-item-success(v-if='notice') {{ notice }}

  //- loading indicator
  li.list-group-item(v-if='loading')
    .ph-item
      .ph-col-12
        .ph-row
          .ph-col-2.big
          .ph-col-2.big.empty
          .ph-col-8.big
        .ph-row
          .ph-col-2.big
          .ph-col-2.big.empty
          .ph-col-8.big
        .ph-row
          .ph-col-2.big
          .ph-col-2.big.empty
          .ph-col-8.big

  //- empty
  li.list-group-item(v-else-if='people.length == 0')
    .my-5.text-muted.text-center.font-italic No guests appeared on this episode.

  //- list
  li.list-group-item(
    v-else=''
    v-for='(person, index) in people'
    :key='person.id'
    :person='person'
    :class="{ disabled: person.removing }"
  )
    .d-flex
      .mr-3
        img.size-75.border(:src='person.image')
      .flex-grow-1
        h5.mt-0.mb-2
          a(:href='person.html_url') {{ person.name }}
        div
          a.btn.btn-sm.btn-outline-danger(
            @click.prevent='removeGuest(person)'
            :class="{ disabled: person.removing }"
          )
            font-awesome-icon(
              :icon="person.removing ? ['fas', 'circle-notch'] : ['fas', 'trash']"
              :spin="person.removing"
            )
            span.ml-1 Remove

  //- add button
  li.list-group-item(v-if='showAdd')
    .d-flex.align-items-center.py-2
      .flex-grow-1
        autocomplete(
          placeholder='Guest name'
          :getResults='searchGuests'
          :disabled='addingGuest'
          v-model='guestToAdd'
          ref='guestAutocomplete'
        )
      .ml-2
        button(class='btn btn-primary' @click.prevent='addGuest' :class='{ disabled: addingGuest }')
          div(v-show='addingGuest')
            span.spinner-border.spinner-border-sm(role='status' aria-hidden='true') Adding...
          div(v-show='!addingGuest') Add guest

</template>

<script>
import Axios from 'axios'

export default {
  props: {
    url: {
      type: String
    },
    showAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      people: [],
      error: '',
      notice: '',
      loading: true,
      addingGuest: false,
      guestToAdd: {}
    }
  },
  methods: {
    searchGuests (query) {
      const existingMembers = this.people.map(x => x.id)
      return new Promise(function (resolve, reject) {
        Axios
          .get('/people.json', { params: { q: query } })
          .then(response => {
            resolve(
              response.data.filter(
                x => (existingMembers.indexOf(x.id) === -1)
              ).slice(0, 5)
            )
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addGuest () {
      return Axios
        .post(this.url, { person: this.guestToAdd })
        .then(response => {
          this.notice = 'Guest was added to episode.'
          this.people.push(this.guestToAdd)
          this.guestToAdd = {}
          this.$refs.guestAutocomplete.clear()
          this.addingGuest = false
        })
        .catch(error => {
          if (error.response.data) {
            if (error.response.data.error) {
              this.error = error.response.data.error
            } else {
              this.error = error.response.data
            }
          } else {
            this.error = error
          }
          this.addingGuest = false
        })
    },
    removeGuest (person) {
      person.removing = true
      return Axios
        .delete(this.url, { params: { 'person[name]': person.name } })
        .then(response => {
          this.notice = 'Guest was removed from episode.'
          const index = this.people.indexOf(person)
          if (index > -1) {
            this.people.splice(index, 1)
          }
        })
        .catch(error => {
          this.error = error
          person.removing = false
        })
    }
  },
  created () {
    const that = this
    const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
    Axios.defaults.headers.common['X-CSRF-Token'] = token
    Axios.defaults.headers.common.Accept = 'application/json'

    Axios
      .get(this.url)
      .then(response => {
        const people = response.data
        for (const person of people) {
          person.removing = false
        }
        that.people = people
        that.loading = false
      })
      .catch(error => {
        that.error = `Error fetching episodes: ${error}`
        that.loading = false
      })
  }

}
</script>

<template lang='pug'>
.alerts.py-3.px-4
  transition-group(name='fade')
    .alert(v-for='(alert, index) in alerts' :class='alertClass(alert)' :key='index') {{ alert.message }}
</template>

<script>
import EventBus from '../packs/eventBus'

export default {
  name: 'alerts',

  data () {
    return {
      alerts: [],
      delay: 3000,
      timer: null
    }
  },

  methods: {
    alertClass (alert) {
      return `alert-${alert.type}`
    },
    receiveAlert (alert) {
      this.alerts.push(alert)
      this.timer = setTimeout(() => {
        this.alerts.splice(this.alerts.indexOf(alert), 1)
      }, this.delay)
    }
  },

  created () {
    EventBus.$on('alert', this.receiveAlert)
  }
}
</script>

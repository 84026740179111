<template lang='pug'>
div
  //- error
  ul.list-group.list-group-flush(v-if='error')
    li.list-group-item.list-group-item-danger {{ error }}

  //- loading
  ul.list-group.list-group-flush(v-else-if='loading')
    li.list-group-item(v-for='index in 5' :key='index')
      slot(name='loading')

  //- list
  lists-people(
    v-else=''
    :collection='people'
    :emptyMessage='emptyMessage'
    :showId='showId',
    :showNewGuest='showNewGuest',
    :hideControls='hideControls',
    :newGuestPlaceholder='newGuestPlaceholder'
  )
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    url: {
      type: String,
      default: '/people.json'
    },
    showId: {
      type: String,
      default: '0'
    },
    showNewGuest: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    emptyMessage: {
      type: String,
      default: 'No guests found.'
    },
    newGuestPlaceholder: {
      type: String,
      default: 'Guest name'
    }
  },
  data() {
    return {
      people: [],
      error: '',
      loading: true
    }
  },
  created () {
    var that = this
    Axios
      .get(this.url)
      .then(response => {
        that.people = response.data
        that.loading = false
      })
      .catch(error => {
        that.error = `Error fetching guests: ${error}`
        that.loading = false
      })
  }

}
</script>

import Vue from 'vue/dist/vue.esm.js'
// import VueSanitize from 'vue-sanitize'
import './fontawesome'

// Vue.use(VueSanitize)

Vue.config.productionTip = false

const requireComponent = require.context(
  // load all components from this folder
  '../components',

  // whether or not to look in subfolders
  true,

  // load all files matching this pattern
  /\.(js|vue)$/i
)

requireComponent.keys().forEach(filename => {
  // Get component config
  const componentConfig = requireComponent(filename)
  const componentName = filename.split('/').join('').match(/\w+/)[0]

  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

window.onload = function () {
  const app = new Vue({ el: '#app' })
  global.app = app
}

<template lang='pug'>
div
  autocomplete#search(
    :placeholder='placeholder'
    :size='size'
    :getResults='search'
    :resultType='resulttype'
    :inputClass='inputclass'
    :inputWrapperClass='inputwrapperclass'
    :autofocus='autofocus'
    :query='query'
    @open='open'
  )
</template>

<script>
import Axios from 'axios'

export default {
  props: {
    resulttype: {
      type: String,
      default: 'dropdown'
    },
    inputclass: {
      type: String,
      default: 'form-control form-control-sm form-search mr-sm-2'
    },
    inputwrapperclass: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '40'
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    query: {
      type: String
    }
  },
  methods: {
    search (query) {
      return new Promise(function (resolve, reject) {
        gtag('event', 'search', { search_term: query })
        Axios
          .get('/search.json', { params: { q: query } })
          .then(response => { resolve(response.data.results) })
          .catch(error => { reject(error) })
      })
    },
    personImage (person) {
      if (person.image) {
        return person.image
      } else {
        return '/assets/placeholder-person.jpg'
      }
    },
    open (result) {
      const url = new URL(window.location)
      const title = document.title.replace('Search', result.query)
      url.searchParams.set('q', result.query)
      window.history.pushState({}, '', url)
      document.title = title
      window.location = result.item.url
    }
  }
}
</script>

<template lang='pug'>
.autocomplete.position-relative
  .alert.alert-danger.mb-3(v-if='error', role='alert') {{ error }}
  input(
    type='text'
    :id='name'
    :class='inputClass'
    :size='size'
    :placeholder='placeholder'
    :autofocus='autofocus'
    :disabled='disabled'
    data-toggle='dropdown'
    @input='onChange'
    @focus='onChange'
    v-model='displayValue'
    @keydown.down='onArrowDown'
    @keydown.up='onArrowUp'
    @keydown.enter.prevent='onEnter'
  )
  .invalid-feedback(:id='errorsName')

  // type == 'dropdown'
  ul.dropdown-menu.w-100(v-show='resultType == "dropdown" && (isOpen || isLoading)')

    // loading
    li.dropdown-item.disabled(v-if='isLoading') Searching...

    // empty
    li.dropdown-item.disabled(v-else-if='emptyResults()') No matches found.

    // array
    li.dropdown-item(
      v-else-if='(Array.isArray(results))'
      v-for='(result, i) in results'
      :key='i'
      @click='setResult(result)'
      :class="{ 'active': i === arrowCounter }"
    )
      .d-flex.flex-row.align-items-center.py-2
        img.size-25.border(:src='result.image')
        .ml-2 {{ renderResult(result) }}

    // object
    div(v-else='' v-for='(sectionResults, sectionName, sectionIndex) in results')
      div(v-if='sectionResults != 0')
        h6.dropdown-header.text-capitalize {{ sectionName }}
        a.dropdown-item.list-group-item-action(
          v-for='(result, i) in sectionResults'
          :href='result.url'
        )
          .d-flex.flex-row.align-items-center.py-2.min-w-0
            img.size-25.rounded.border(:src='result.image')
            .ml-2.text-truncate {{ result.display }}

  // type == 'listgroup'
  .mt-3.my-neg-3(v-if='resultType == "listgroup" && (isOpen || isLoading)')

    // loading
    .card(v-if='isLoading')
      .card-header Search results
      .card-body.py-5
        p.my-5.text-muted.text-center Searching...

    // empty
    .card(v-else-if='emptyResults()')
      .card-header Search results
      .card-body.py-5
        p.my-5.text-muted.text-center No matches found.

    // object
    div(v-else='' v-for='(sectionResults, sectionName, sectionIndex) in results')
      div(v-if='sectionResults != 0')
        .card
          .card-header {{ sectionName }}
          .list-group.list-group-flush
            a.list-group-item.list-group-item-action(
              v-for='(result, i) in sectionResults'
              :href='result.url'
              @click.prevent='openResult(result)'
            )
              .d-flex.flex-row.align-items-center.min-w-0
                img.size-35.rounded.border(:src='result.image')
                .ml-2.text-truncate {{ result.display }}

</template>

<script>
export default {
  name: 'autocomplete',

  props: {
    name: {
      type: String,
      default: 'autocomplete'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    getResults: {
      type: Function,
      required: false
    },
    size: {
      type: String
    },
    inputClass: {
      type: String,
      default: 'form-control mr-2'
    },
    inputWrapperClass: {
      type: String,
      default: ''
    },
    resultType: {
      type: String,
      default: 'dropdown'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    query: {
      type: String
    },
    displayField: {
      type: String,
      default: 'display_name'
    }
  },

  data () {
    return {
      isOpen: false,
      results: [],
      value: {},
      error: '',
      displayValue: '',
      isLoading: false,
      arrowCounter: 0
    }
  },

  methods: {
    onChange () {
      if (this.value.name !== this.displayValue) {
        this.value = { name: this.displayValue }
      }

      if (this.displayValue.length < 3) {
        this.isOpen = false
        return
      }

      this.isLoading = true
      this.isOpen = true

      return this.getResults(this.displayValue)
        .then(results => {
          this.results = results.data || results
          this.isLoading = false

          // if the user typed (or pasted) a name matching a result, we should update value to that match
          let resultList = this.results
          if (!Array.isArray(this.results)) {
            resultList = []
            for (const [k, v] of Object.entries(results)) {
              resultList = resultList.concat(v)
            }
          }
          for (const resultItem of resultList) {
            if (resultItem.name === this.displayValue) {
              this.value = resultItem
            }
          }
        })
        .catch(error => {
          this.error = error
          this.results = []
          this.isLoading = false
        })
    },
    emptyResults () {
      if (Array.isArray(this.results)) {
        return (this.results.length === 0)
      } else {
        if (!this.results) { return false }
        for (const [k, v] of Object.entries(this.results)) {
          if (v.length > 0) { return false }
        }
        return true
      }
    },
    setResult (result) {
      this.value = result
      this.displayValue = this.renderResult(result)
      this.isOpen = false
      this.$emit('select', { item: result, query: this.displayValue })
    },
    openResult (result) {
      this.$emit('open', { item: result, query: this.displayValue })
    },
    renderResult (result) {
      if (typeof result === 'string') {
        return result
      } else {
        return result[this.displayField]
      }
    },
    onArrowDown (evt) {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp () {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter () {
      if (Array.isArray(this.results) && this.results[this.arrowCounter] && this.isOpen) {
        this.setResult(this.results[this.arrowCounter])
        this.arrowCounter = -1
      } else {
        this.setResult({ name: this.displayValue })
      }
      this.$emit('enter', this.value)
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    },
    clear () {
      this.displayValue = ''
      this.value = {}
    }
  },
  watch: {
    value () {
      this.$emit('input', this.value)
    }
  },
  computed: {
    errorsName () {
      return `${this.name}_errors`
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    if (this.query) {
      this.displayValue = this.query
      this.onChange()
    }
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<template lang='pug'>
div
  div.input-group

    // type selector
    div.input-group-prepend
      select.custom-select(v-model='mode')
        option(value='url') URL
        option(value='file') File

    // url input
    input.form-control(
      v-if="mode == 'url'" type='text' placeholder='URL to image' v-model='imageURL' :id='urlInputID'
    )

    // file input
    div.custom-file(v-else='')
      input.custom-file-input(
        type='file' placeholder='Upload file' v-on:change='fileChanged' :id='fileInputID'
      )
      label.custom-file-label(:for='fileInputID') {{ label }}

    // (hidden) data input
    input(type='hidden' v-model='imageData' :name='dataInputName' :id='dataInputID')

  // preview
  div.mt-3(v-if='imageData != null')
    div.mb-2 Image preview

    // preview image
    img(:src='imageData' class='img-thumbnail' style='height: 250px' v-if='imageData != ""')

    // preview loading
    div.input-image-preview(v-if='imageData == ""')
      .ph-item
        .ph-col-12
          .ph-picture
</template>

<script>
export default {
  name: 'image-form',

  props: {
    resource: {
      type: String,
      default: 'show'
    },
    image: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      mode: 'file',
      label: 'Choose file',
      imageData: null,
      imageURL: null,
      loadingImageURL: false
    }
  },
  computed: {
    dataInputName: function () {
      return `${this.resource}[image]`
    },
    dataInputID: function () {
      return `${this.resource}_image`
    },
    urlInputID: function () {
      return `${this.resource}_image_url`
    },
    fileInputID: function () {
      return `${this.resource}_image_file`
    }
  },
  methods: {
    fileChanged (event) {
      this.imageData = ''
      this.label = event.target.value.replace('C:\\fakepath\\', '')
      const files = event.target.files[0]
      const that = this
      if (files) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(files)
        fileReader.addEventListener('load', function () {
          that.imageData = this.result
        })
      } else {
        this.imageData = null
      }
    }
  },

  mounted () {
    if (this.image) {
      this.imageData = this.image
    }
    this.mode = localStorage.getItem('imageFormMode') || 'url'
  },

  watch: {
    mode: function (val) {
      localStorage.setItem('imageFormMode', val)
    },
    imageURL: function (val) {
      if (!this.loadingImageURL) {
        setTimeout(() => {
          this.imageData = this.imageURL
          this.loadingImageURL = false
        }, 1000) // 1 sec delay
      }
      this.loadingImageURL = true
    }
  }
}
</script>

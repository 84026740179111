<template lang='pug'>
.notifications
  notification-list(:notifications='notifications' @removeNotification='removeNotification')
</template>

<script>
import Axios from 'axios'
import EventBus from '../packs/eventBus'

export default {
  name: 'notification-page',

  data () {
    return {
      notifications: [],
      notificationCount: 0,
      markAsReadTimer: null,
      markAsReadTimout: 2000 // 2 seconds
    }
  },
  methods: {
    markAsRead () {
      const that = this
      Axios
        .patch('/notifications/read.json')
        .then(response => {
          that.notifications = response.data
          that.notificationCount = that.notifications.filter(x => (!x.read)).length
        })
        .catch(error => {
          EventBus.$emit('alert', {
            type: 'danger',
            message: `Could not mark as read: ${error}.`
          })
        })
    },
    removeNotification (notification) {
      const that = this
      Axios
        .delete(`/notifications/${notification.id}.json`)
        .then(response => {
          that.notifications = that.notifications.splice(that.notifications.indexOf(notification), 1)
          that.notificationCount = that.notifications.filter(x => (!x.read)).length
          EventBus.$emit('alert', {
            type: 'success',
            message: 'Notification was successfully deleted.'
          })
        })
        .catch(error => {
          EventBus.$emit('alert', {
            type: 'danger',
            message: `Could not delete notification: ${error}.`
          })
        })
    }
  },

  mounted () {
    const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
    Axios.defaults.headers.common['X-CSRF-Token'] = token
    Axios.defaults.headers.common.Accept = 'application/json'

    const that = this
    Axios
      .get('/notifications.json')
      .then(response => {
        that.notifications = response.data
        that.notificationCount = that.notifications.filter(x => (!x.read)).length
      })
      .catch(error => {
        EventBus.$emit('alert', {
          type: 'danger',
          message: `Could get notifications: ${error}.`
        })
      })

    this.markAsReadTimer = setTimeout(this.markAsRead, this.markAsReadTimout)
  }
}
</script>

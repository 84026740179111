<template lang='pug'>
li.nav-item.ml-2.ml-lg-3.ml-xl-4.dropdown.notifications

  // icon
  a#notification-menu.nav-link.dropdown-toggle(
    href='#' role='button' 'data-toggle'='dropdown' 'aria-haspopup'=true 'aria-expanded'=false
  )
    font-awesome-layers.fa-fw
      font-awesome-icon( :icon="notificationCount > 0 ? ['fas', 'bell'] : ['far', 'bell']" )
      font-awesome-layers-text( counter=true :value='notificationCount' v-if='notificationCount > 0' )

  // menu
  .dropdown-menu.dropdown-menu-right( 'aria-labelledby'='notification-menu' ref='notificationMenu' )
    notification-list.rounded(
      :notifications='notifications'
       @removeNotification='removeNotification'
    )
</template>

<script>
import Axios from 'axios'
import EventBus from '../packs/eventBus'

export default {
  name: 'notification-menu',

  data () {
    return {
      notifications: [],
      notificationCount: 0,
      markAsReadTimer: null,
      markAsReadTimout: 2000 // 2 seconds
    }
  },
  methods: {
    markAsRead () {
      this.error = ''
      const that = this
      Axios
        .patch('/notifications/read.json')
        .then(response => {
          that.notifications = response.data
          that.notificationCount = that.notifications.filter(x => (!x.read)).length
        })
        .catch(error => {
          EventBus.$emit('alert', {
            type: 'danger',
            message: `Could not mark as read: ${error}.`
          })
        })
    },
    onClassChange (classAttrValue) {
      const classList = classAttrValue.split(' ')
      if (classList.includes('show')) {
        this.markAsReadTimer = setTimeout(this.markAsRead, this.markAsReadTimout)
      } else {
        clearTimeout(this.markAsReadTimer)
      }
    },
    removeNotification (notification) {
      const that = this
      Axios
        .delete(`/notifications/${notification.id}.json`)
        .then(response => {
          that.notifications = that.notifications.splice(that.notifications.indexOf(notification), 1)
          that.notificationCount = that.notifications.filter(x => (!x.read)).length
          EventBus.$emit('alert', {
            type: 'success',
            message: 'Notification was successfully deleted.'
          })
        })
        .catch(error => {
          EventBus.$emit('alert', {
            type: 'danger',
            message: `Could not delete notification: ${error}.`
          })
        })
    }
  },

  mounted () {
    this.observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName)
        this.$nextTick(() => {
          this.onClassChange(newValue, m.oldValue)
        })
      }
    })

    this.observer.observe(this.$refs.notificationMenu, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class']
    })

    const that = this
    Axios
      .get('/notifications.json')
      .then(response => {
        that.notifications = response.data
        that.notificationCount = that.notifications.filter(x => (!x.read)).length
      })
      .catch(error => {
        EventBus.$emit('alert', {
          type: 'danger',
          message: `Could get notifications: ${error}.`
        })
      })
  },
  beforeDestroy () {
    this.observer.disconnect()
  }
}
</script>
